<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
         <RetailerMenu></RetailerMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-3">        
        <v-row class="rowBg">
          <v-col cols="12">
            <!-- Search using Tracking ID -->
            <v-row wrap>
              <v-col cols="12">
                <v-text-field
                  v-model="search"
                  outlined
                  label="Search using tracking Id...."
                  dense
                  append-icon="mdi-magnify"
                  single-line
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row wrap>
              <v-col cols="12">
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="reversedOrders"
                  item-key="name"
                  class="elevation-1"
                  :search="search"
                >

                  <template v-slot:item.trackingNumber="{ item }">
                    <v-layout wrap>
                      <span>{{ item.trackingNumber }}</span>
                    </v-layout>
                  </template> 

                  <template v-slot:item.createdAt="{ item }">
                    <v-layout wrap>
                      <span>{{ formattedCreatedAt(item.createdAt) }}</span>
                    </v-layout>
                  </template>


                  <template v-slot:item.status="{ item }">
                    <v-layout v-if="item.status == 'deleted'">
                      <span style="color:#BD2121">Order {{ item.status }}</span>
                    </v-layout>
                    <v-layout v-else-if="item.status == 'created'">
                      <span style="color:green">New Order {{ item.status }}.</span>
                    </v-layout>
                    <v-layout v-else-if="item.status == 'awaiting-delivery'">
                      <span style="color:black">Order {{ item.status }}</span>
                    </v-layout>
                    <v-layout v-else-if="item.status == 'approved'">
                      <span style="color:#BD2121">Order Dispatched</span>
                    </v-layout>
                    <v-layout v-else>
                      <span style="color:black">Order {{ item.status }}</span>
                    </v-layout>
                  </template>


                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="ml-2"
                      v-on:click="editItem(item)"
                    >
                      mdi-dots-grid
                    </v-icon>
                  </template>
                </v-data-table>

                <v-dialog v-model="orderInfoDialog" width="600">
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Order Details
                      <v-spacer></v-spacer>
                      <v-btn icon @click="orderInfoDialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text>
                      <v-layout wrap>
                        <v-col cols="12">
                           <p><b>Order Id:</b> {{orderItem.orderId}}</p>
                           <p><b>Tracking Number:</b> {{orderItem.trackingNumber}}</p>
                           <p><b>Order Date:</b> {{formattedCreatedAt(orderItem.createdAt)}}</p>
                        </v-col>
                      </v-layout>
                      <v-layout wrap>
                        <v-col cols="12">
                          <h5>Ordered Items</h5>
                          <v-divider></v-divider>
                           <v-row  v-for="(order, index) in orderItem.OrderItems"
                              :key="index" style="border-bottom:1px solid black; margin-bottom:10px;">
                              <template v-if="order.Product.User.userId == userId">
                                <v-col cols="3">
                                <v-img
                                    class="ma-2"
                                    width="70"
                                    height="70"
                                    :src="order.Product.productImagePath"
                                    contain
                                />
                                </v-col>
                                <v-col cols="8">
                                  <p><b>Product Name:</b> {{order.Product.name}}<br>
                                  <b>Product Price:</b> GH₵ {{order.Product.price}}<br>
                                  <b>Quantity:</b> {{order.qty}}<br>
                                  <b>Total Cost:</b> GH₵ {{order.total}}<br>
                                  </p>
                                  <v-card-actions>
                                    <v-layout>
                                      <v-col cols="12">
                                        <v-progress-linear
                                          class="mt-3"
                                          color="#7CBF46"
                                          v-show="loader"
                                          :indeterminate="true"
                                        ></v-progress-linear>
                                      </v-col>
                                    </v-layout>
                                   
                                     <v-row v-if="order.status == 'created'">
                                      <v-col cols="12" align="left" justify="left">
                                          <p style="color:red;">Awaiting pickup from Logistics Company.</p>
                                      </v-col>
                                    </v-row>
                                  </v-card-actions>
                                </v-col>
                              </template>
                           </v-row>
                          
                        </v-col>
                      </v-layout>
                      <v-layout wrap>
                        <v-col cols="12">
                          <h5>Logitics Company</h5>
                          <v-divider></v-divider>
                           <v-row>
                            <v-col cols="12">
                              <p><b>Company name:</b> {{orderItem.LogisticsCompany.name}}</p>
                              <p><b>Contact Person:</b> {{orderItem.LogisticsCompany.contactPerson}}</p>
                              <p><b>Email:</b> {{orderItem.LogisticsCompany.email}}</p>
                            </v-col>
                           </v-row>

                        </v-col>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RetailerMenu from "../others/RetailerMenu.vue";
import moment from 'moment';


export default {
  name: "UserShopOrderList",

  components: {
    RetailerMenu,
  },

  data: () => ({
    loader:false,
    orderInfoDialog: false,
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/overview/retailer_dashboard/home",
      },
      {
        text: "Orders",
        disabled: true,
        href: "/",
      },
    ],
    filters: false,
    menu: false,
    orders:[],
    search: "",
    selected: [],
    orderSearch: {
      orderNumber: "",
      product: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISO,
    },
    orderNumberRules: [
      (v) => !!v || "Order number is required",
      (v) => v.length <= 10 || "Number must be less than 10 characters",
    ],
    statusItems: [
      "All Orders",
      "Awaiting Payment",
      "Awaiting Cancellation",
      "Awaiting Delivery",
      "Order Complete",
      "Frozen Orders",
      "Payment not confirmed",
    ],
    OrderAwaitingDelivery:[],
    OrdersDeleted:[],
    OrdersCompleted:[],
    orderItem:{
      LogisticsCompany:{
        name:"",
        contactPerson:"",
        email:"",
      }
    },
    userId:''
  }),
  computed: {
    headers() {
      return [
        { text: "Tracking Id", value: "trackingNumber" },
        { text: "Order Date", value: "createdAt" },
        { text: "Order Status", value: "status" },
        { text: "Action", value: "actions" },
      ];
    },
    reversedOrders() {
      // Use the reverse method to reverse the order of the items
      return this.orders.slice().reverse();
    }
  },
  created() {
    this.fetchOrders();
    this.userId = localStorage.getItem("userId");
  },
  methods: {
    toggleFilters() {
      this.filters = !this.filters;
    },
    formattedCreatedAt(createdAt) {
      // Use moment to format the date
      return moment(createdAt).format('YYYY-MM-DD HH:mm:ss');
      // Adjust the format string as per your requirement
    },
    fetchOrders() {
        this.$http
        .get(`${process.env.VUE_APP_URL}orders`)
          .then((response) => {
            response.data.data.forEach(element => {
                element.OrderItems.forEach(e => {
                    if(e.Product.User.userId == localStorage.getItem("userId") )
                    {
                        console.log(element);
                        this.orders.push(element);
                    }
                });
                
            });
        });
    },
    editItem(item) {
      this.orderItem = item;
      this.orderInfoDialog = true;
    },

  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
}

.iconPadding {
  padding: 20px !important;
}

.iconStyle {
  color: yellowgreen;
  font-size: 45px;
}

.orderStatBox {
  padding-top: 30px !important;
  border-radius: 10px;
  text-align: center;
  margin: 10px;
}

.orderStatText {
  color: white;
  font-size: 16px;
  text-align: center;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
